<template>
  <default-layout>
    <div class="container help--container">
      <banner :banner-text="bannerText" />
      <div class="help--wrapper">
        <div class="left--col">
          <page-select></page-select>
        </div>
        <div class="right--col" v-if="selectedHelp">
          <div class="help--content">
            <div class="content--text">
              <rentfix-general v-if="selectedHelp.url === 'what-is-beliruma'"></rentfix-general>
              <about-project v-else-if="selectedHelp.url === 'about-project'"></about-project>
            </div>
          </div>
        </div>
      </div>
    </div>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
import Banner from '@/components/utils/page-banner';
import PageSelect from '@/components/utils/page-select';
// const BeliRumaGeneral = () => import('@/components/static-page/help/rentfix-general');
const AboutProject = () => import('@/components/static-page/help/seputar-project');
import DefaultHeadMixin from '@/mixins/defaultHead';
import { mapState } from 'vuex';

export default {
  mixins: [DefaultHeadMixin],
  components: {
    DefaultLayout,
    Banner,
    PageSelect,
    // BeliRumaGeneral,
    AboutProject,
  },
  computed: {
    ...mapState({
      selectedHelp: (state) => state.v2.help.selectedHelp,
      helpList: (state) => state.v2.help.helpList,
    }),
  },
  data: () => ({
    bannerText: 'FAQ.helpCenter',
  }),
  mounted() {
    if (this.$route.params.type) {
      const help = this.helpList.find((help) => {
        return help.url === this.$route.params.type;
      });
      this.$store.commit('v2/help/SET_SELECTED_HELP', help);
    } else {
      this.$store.commit('v2/help/SET_SELECTED_HELP', this.helpList[0]);
    }
  },
};
</script>
